import { Notify } from 'notiflix/build/notiflix-notify-aio';
import debounce from 'lodash.debounce';

const form = document.querySelector('.form');
const name = document.querySelector('#name');
const phone = document.querySelector('#phone');
const message = document.querySelector('textarea');
const btn = document.querySelector('.btn');

const CHAT_ID = '-1002460627067';
const TOKEN_BOT = '7505711727:AAE_niJV90fbzvsqACQlUZLHLOC3w5JPTbc';
const URI_API = `https://api.telegram.org/bot${TOKEN_BOT}/sendMEssage`;

form.addEventListener('submit', handleSubmit);
form.addEventListener('input', debounce(handleChange, 300));

onTextTextarea();

function handleChange() {
  const nameClient = name.value;
  const messgaeClient = message.value;
  const phoneClient = phone.value;

  const option = {
    name: nameClient,
    phone: phoneClient,
    message: messgaeClient,
  };

  localStorage.setItem('user', JSON.stringify(option));
}

function onTextTextarea() {
  const infoClient = JSON.parse(localStorage.getItem('user'));

  if (!infoClient) {
    return;
  }

  name.value = infoClient.name;
  phone.value = infoClient.phone;
  message.value = infoClient.message;
}

async function handleSubmit(event) {
  event.preventDefault();
  const form = event.target;

  const phone = form.phone.value;
  const name = form.name.value;
  const message = form.message.value;

  const user = {
    name,
    message,
    phone,
  };

  if ((name.trim() === '') | (phone.trim() === '')) {
    return Notify.failure('Name and phone number are required!');
  }

  let text = `Name:   ${name}\n`;
  text += `Number Phone: ${phone}\n`;
  text += `Message:   ${message}\n`;

  try {
    const res = await fetch(URI_API, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        chat_id: CHAT_ID,
        parse_mode: 'html',
        text,
      }),
    });

    if (res.ok) {
      Notify.success('Thank you, we will connect soon with you.');
      btn.disabled = true;
      setTimeout(() => {
        btn.disabled = false;
      }, 3000);
      return form.reset();
    }
    throw new Error(res.message);
  } catch (error) {
    console.error(error);
  }
}
